export default {
  title: "Side B",
  tracks: [
    {
      title: "Raak 'n bietjie fucked",
      lyrics: "Hierdie jaar was 'n harde een\nMoes oor begin vanaf kindsbeen\nEn ek is moeg om in die ry te wag\n\nDie aarde draai al biljoene kere\nEn almal gaan nog steeds tekere\nDie samelewing is eintlik net 'n las\n\nEk sal eerder weg wil breek\nEn van al hierdie kak vergeet\nSo ek rol solank klippe uit die pad\n\nEk wil na die kus verdwyn\nMet 'n hele kis vol branewyn\nBuddy kom ons raak 'n bietjie fucked\n\nBuddy kom ons raak 'n bietjie fucked\nBuddy kom ons raak 'n bietjie fucked\nBuddy kom ons raak 'n bietjie fucked\n\nAs ons uit hierdie plek kan kom\nWag daar vir ons 'n tweede son\nMaar hierdie vier mure druk my vas\n\nMensdom wanneer gaan jy ooit leer\nOns almal word gehipnotiseer\nMet lewens uitgestal op 'n rak\n\nOn sal maar 'n brug moet bou\nSterk genoeg om ons te hou\nDit wat jy wil hê sal jy moet vat\n\nMaar ek gaan na die kus verdwyn\nMet 'n hele kis vol branewyn\nBuddy kom ons raak 'n bietjie fucked\n\nBuddy kom ons raak 'n bietjie fucked\nBuddy kom ons raak 'n bietjie fucked\nBuddy kom ons raak 'n bietjie fucked\nBuddy kom ons raak 'n bietjie fucked\nBuddy kom ons raak 'n bietjie fucked\nBuddy kom ons raak 'n bietjie fucked"
    },
    {
      title: "My Dolla Nee",
      lyrics: "Ek is moeg tot in die siel\nHierdie jaar het my verniel\nMiskien is die volgende een beter\nMy voorneme is n skoner gewete\n\nEk sukkel nogsteeds om te slaap\nEn ontslae te raak van my selfhaat\nDie peilings dui dat ons almal siek is\nAlles in die lewe is siklus\n\nOm en om en om en om\nWeer en weer en weer en weer\nStadig oor die klippertjies\nVoordat jy die ritme breek\n\nOm en om en om en om\nWeer en weer en weer en weer\nNee O nee my dolla nee\nDaar moet iets beters wees?\n\nDit voel soms of ek wil breek\nMaar ek weet ek moet dit wegsteek\nMiskien voel ek more bietjie beter\nAsseblief iemand gee vir my rede\n\nEn as ek alles môre op sou gee\nSou jy nogsteeds alles met my deel\nOns dink ons hardloop weg van ons verlede\nMaar ons almal hardloop sonder rede\n\nOm en om en om en om\nWeer en weer en weer en weer\nStadig oor die klippertjies\nVoordat jy die ritme breek\n\nOm en om en om en om\nWeer en weer en weer en weer\nNee O nee my dolla nee\nDaar moet iets beters wees?"
    },
    {
      title: "Seeman",
      lyrics: "Môre sal ek dit onthou\nVroeg voor die oggend dou\nWaar sal ek heen?\nO waar sal ek gaan?\nWaar kom ek dan nou eintlik vandaan?\n\nDit skeur as jou naels in my druk\nDie monster wat my kom verslind\nWaar sal ek heen?\nO waar sal ek gaan?\nWaar kom ek nou eitlik vandaan?\n\nKan jy jou kop nog ophou?\nEn kan jy die storms beur?\nKan jy als insit in 'n oogwink\nEn die res als vergeet?\n\nEn ken jy jou diepste donker?\nEn het jy hom al ontmoet?\nKan jy hom dan leer om te redineer\nEn jou nog 'n kans te gee\n\nRoep sy oor die diepblou\nMiskien nog na jou?\nSal jy ook dan seil\nNa die waters vuil?\nWaar menigde seeman vergaan\n\nEggo dit or die berge\nEn laat dit uitbasein\nSkree jou kreet sodat almal weet\nDat die hartseerste man dra geen pyn\nDat die hartseerste man dra geen pyn\n\nMore sal ek dit onthou\nVroeg voor die oggend dou\nWaar sal ek heen?\nO waar sal ek gaan?\nWaar kom ek dan nou eitlik vandaan?\n\nDit skeur as jou naels in my druk\nDie monster wat my kom verlsind\nWaar sal ek heen?\nO waar sal ek gaan?\nWaar kom ek dan nou eitlik vandaan?"
    }
  ]
}