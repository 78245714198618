export default {
  title: "Saam Alleen",
  tracks: [
    {
      title: "Saam Alleen",
      lyrics: `Ek hoor jy hou ook van alleen wees en jy skryf so mooi
      Ek wonder kan jy die een wees wat my legkaart kom voltooi
      Miskien ken ek jou glad nie, maar ek voel ek doen
      Ek sien alreeds jou glimlag, ek voel alreeds jou soen
      Jou mooiste blonde hare, lok my vinnig in
      Jou oë soos diep poele, en ek verdrink daarin

      Kom ons wees vir altyd saam alleen
      Jy’s soos aarbeiwyn in die somerreën
      Ek’t min maar vir jou sal ek alles gee
      Selfs die klere van my lyf afskeer

      Kom ons wees vir altyd saam alleen
      Ek sal dans saam met jou in die reën
      Ek’s nie rerig een vir cheesy love songs nie
      Maar vir jou skryf ek ‘n simfonie
      Simfonie
      Simfonie
      Simfonie
      Simfonie

      Ek hoop in 5 jaar se tyd sit jy hier langs my
      En ons gaan op ‘n avontuur in die wildtuin
      Jy maak my innig glimlag as jy loer vir my
      Jy fluister in my oor en sê jy’s lief vir my
      Liefde en soetwyn hang in die huis
      En waarokal ons twee saamgaan voel ons altyd tuis

      Kom ons wees vir altyd saam alleen
      Jy’s soos aarbeiwyn in die somerreën
      Ek’t min maar vir jou sal ek alles gee
      Selfs die klere van my lyf afskeer

      Kom ons wees vir altyd saam alleen
      Ek sal dans saam met jou in die reën
      Ek’s nie rerig een vir cheesy love songs nie
      Maar vir jou skryf ek ‘n simfonie
      Simfonie
      Simfonie
      Simfonie
      Simfonie

      En ek weet dis nou net drome, maar miskien word dit waar
      En soos twee hopeless lovers is ons bedoel vir mekaar
      Ek sal met God onderhandel, Here maak jou prys
      Want in hierdie wêreld, is daar net sy

      Kom ons wees vir altyd saam alleen
      Jy’s soos aarbeiwyn in die somerreën
      Ek’t min maar vir jou sal ek alles gee
      Selfs die klere van my lyf afskeer

      Kom ons wees vir altyd saam alleen
      Ek sal dans saam met jou in die reën
      Ek’s nie rerig een vir cheesy love songs nie
      Maar vir jou skryf ek ‘n simfonie
      Simfonie
      Simfonie

      Kom ons wees saam alleen
      `,
    },
  ],
};
