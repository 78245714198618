export default {
  title: "Bly Versigtig",
  tracks: [
    {
      title: "Bly Versigtig",
      lyrics: `Druk die knoppie, sit my aan, sê my waarheen ek moet gaan
      Sluk jou koffie, vinnig af vir die dag wat voor jou wag

      Sit jou masker, gou-gou aan voor jy by die deur uitgaan
      Lyk gelukkig, bly versigtig dat jy onder die radar vergaan

      Jy huis toe kom vanaand
      Gaan jy huis toe kom vanaand?

      Sonder rigting, leë gesigte wat vanaf 'n bakkie uitstaar
      Harde drome, sagte kussings; die gevange van my gemak

      Die gedagtes, reeds besigtig, die verwagting is verskriklik
      Lyk gelukkig, bly versigtig dat jy onder die radar vergaan

      Jy huis toe kom vanaand
      Gaan jy huis toe kom vanaand?

      Gaan jy huis toe kom vanaand?
      Gaan jy huis toe kom vanaand?
      `,
    },
  ],
};
