export default {
  title: "Iets Is Fokken Fout Met My",
  tracks: [
    {
      title: "Iets Is Fokken Fout Met My",
      lyrics: `Ek is die groot verwagting van die aand
      Wat hang soos vuil wasgoed op die draad
      Reflekteer soos n dier se oog voor 'n kar
      Gevang in die koplig van wat aangaan

      Moue oprol voor ons val in die modder
      Ons moet opstaan voordat ons kan vorder
      Eendag sal ons terug kyk en kan lag
      Maar vir nou eers moet ons bietjie afkak

      So rol my op en rook my klaar
      Die nag is donker en vol gevaar
      Ek loop soms net by jou verby
      Ek weet iets is fout
      Iets is fokken fout met my

      Te veel môres,  nou is wat ons verloor
      Nooit weer nie, een keer te veel gehoor
      Ek is bang as ek bel dan tel jy op
      Ek praat eerder met die stem in my kop

      Besmet my met die gees van anargie
      Soek jouself tot jy sukkel om te sien
      Eendag sal ons terug kan kyk en lag
      Maar vir nou eers moet ons bietjie afkak

      So rol my op en rook my klaar
      Die nag is donker en vol gevaar
      Ek loop soms net by jou verby
      Ek weet iets is fout
      Iets is fokken fout met my
      `,
    },
  ],
};
