export default {
  title: "Side A",
  tracks: [
    {
      title: "Kopstamp",
      lyrics: "Ek het verward vanoggend wakker geraak\nDit het gevoel of alles binne my begin te staak\nMy perspektief is nou meer selektief as gisteraand\nMaar ek is mos ‘n heer in hierdie revolusie\nFok jou idees gevind op televisie\n\nEk sal weer my kopstamp\nAs ek meer alleen stap\nEn ek probeer nog poes hard\nMy demone aanvat\n\nEk sal weer my kopstamp\nAs ek meer alleen stap\nEn ek probeer nog poes hard\nMy demone aanvat\n\nDie reg van toegang was voorbehou\nDie teenmiddels het hulself om en om my gevou\nEk het alreeds van my besluite berou\nEn nou is ek besig om aan die laaste morsel\nVan wie ek is te klou\n\nEk sal weer my kopstamp\nAs ek meer alleen stap\nEn ek probeer nog poes hard\nMy demone aanvat\n\nEk sal weer my kopstamp\nAs ek meer alleen stap\nEn ek probeer nog poes hard\nMy demone aanvat\n\nMy moer is suur want jy speel god!\nJy is besig om almal oor te fok!\n\nMy moer is suur want jy speel god!\nJy is besig om almal oor te fok!\n\nIs jy dan so onbewus van waar die waarheid werklik rus?\nIs jy dan so onbewus van wat die waarheid werklik is?\n\nEk sal weer my kopstamp\nAs ek meer alleen stap\nEn ek probeer nog poes hard\nMy demone aanvat"
    },
    {
      title: "Middelklas Middelman",
      lyrics: "Skep my eie droomland, 'n Wonderwerk wêreld\nWek my eie energie op, maar kom nooit nêrens\nVoorgee vergifinis vernietig my siel\nEk moet twee keer kyk om myself in die spieël te sien\n\nMy mure hoog gebou, oorlewingsmeganisme melodie\nVreemde verandering, breek nie bande nie\nSomer sonder sondes lanklaas gesien\nJou groenhuisgasse verlaat nie my atmosfeer nie\n\nMiddelklas Middelman\nMy middelvinger vasgevang\nMiddelklas Middelman\nMy middeljare misbeplan\n\nKaalvoet kunsprojek, nou net normaal\nSkakel in die sterrestelsel, noemingswaardig neutraal\nTeveel terugslae trotseer sonder rede\nEk moet ophou vasklou aan 'n gebroke verlede\n\nWerk ek vir 'n lewe, of lewe ek vir 'n werk?\nDie dae is genommer, vervaldatums beperk\nDie drome dreun soos n brander\nEk sukkel steeds om te verander\n\nMiddelklas Middelman\nMy middelvinger vasgevang\nMiddelklas Middelman\nMy middeljare misbeplan"
    },
    {
      title: "Skiet Reënboë Op Fascisme",
      lyrics: "Ek is bang om die waarheid te skryf\nDalk klim die monsters uit my binneste uit\nEk't al vir jare hul in hokke gesluit\nMaar nou's hul honger en ek dink dit is tyd\n\nJy is bang om die waarheid te hoor\nOf net miskien om jou skuld te verloor\nMaar my monsters kom fluister in jou oor\nDit is tyd om hierdie trein te ontspoor\n\nReënboë, dra nie reën nie\nLiefde, kan nie altyd wen nie\nSondae is nie net vir rus nie\nHou op, nee ek's nie lus nie\n\nOorloë sal nooit stop nie\nHartseer hou nooit op nie\nVrede kan nie die haat stop nie\nLewe is nie net vir ons nie\n\nJy is bang dat die waarheid versprei\nRaak kwaad en wys jou vingers na my\nMaar die waarheid sal jou vingers afsny\nNog kos vir hierdie monsters van my\n\nEk is bang dat jy my woorde uitroei\nMaar hierdie keel het al baie gebloei\nHierdie keer jou les geleer\nWees versigtig want die waarheid maak seer\n\nSkape volg nie altyd herders\nDrake groei nie altyd vlerke\nJare is nie sonder merke\nWaarlik die mens het geen perke\n\nTyd sal nooit stop nie\nLewe hou nooit op nie\nProbleme nie net in my kop nie\nHere voel niemand 'n fok nie\n\nVoel niemand 'n fok nie? x4\n\nSkape volg nie altyd herders\nDrake groei nie altyd vlerke\nJare is nie sonder merke\nWaarlik die mens het geen perke\n\nTyd sal nooit stop nie\nLewe hou nooit op nie\nProbleme nie net in my kop nie\nHere voel niemand 'n fok nie"
    },
  ]
}
