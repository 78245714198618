export default [
  {
    id: 10,
    title: "Soos 'n Brander",
    url: "https://lnkfi.re/DnvaCxWI",
    img: "/static/img/artwork/soos-n-brander.png",
    details: [
      {
        key: "Title",
        value: "Soos 'n Brander",
      },
      {
        key: "Album",
        value: "Grootword",
      },
      {
        key: "Released",
        value: "2 November 2023",
      },
      {
        key: "Label",
        value: "Independent",
      },
      {
        key: "Genre",
        value: "Afrikaans Rock",
      },
      {
        key: "Artwork",
        value: "@disarnold",
        href: "http://instagram.com/disarnold",
      },
      {
        key: "Produced",
        value: "@joellismusic",
        href: "http://instagram.com/joellismusic",
      },
      {
        key: "Lyrics",
        value: "Here",
        navlink: "/lyrics/soos-n-brander",
      },
    ],
    released: true,
    stores: [
      {
        url: "https://open.spotify.com/track/26OhBbnbYB8K3Qj0nyP4JN",
        name: "spotify",
      },
      {
        url: "https://music.youtube.com/watch?v=An9N96vulbs",
        name: "youtube",
      },
      {
        url: "https://music.apple.com/za/album/soos-n-brander/1711642626?i=1711642627",
        name: "apple",
      },
      {
        url: "https://www.deezer.com/us/track/2499077821",
        name: "deezer",
      },
    ],
  },
  {
    id: 9,
    title: "Somer Sonder Sondes",
    url: "https://distrokid.com/hyperfollow/kontras/somer-sonder-sondes",
    img: "/static/img/artwork/somer-sonder-sondes.jpeg",
    details: [
      {
        key: "Title",
        value: "Somer Sonder Sondes",
      },
      {
        key: "Album",
        value: "Somer Sonder Sondes",
      },
      {
        key: "Released",
        value: "26 November 2021",
      },
      {
        key: "Label",
        value: "Independent",
      },
      {
        key: "Genre",
        value: "Afrikaans Rock",
      },
      {
        key: "Artwork",
        value: "@nannaventer",
        href: "http://instagram.com/nannaventer",
      },
      {
        key: "Produced",
        value: "@joellismusic",
        href: "http://instagram.com/joellismusic",
      },
      {
        key: "Lyrics",
        value: "Here",
        navlink: "/lyrics/somer-sonder-sondes",
      },
    ],
    released: true,
    stores: [
      {
        url: "https://open.spotify.com/album/0gvd1Y7lb6ydVTu6bDQCIa",
        name: "spotify",
      },
      {
        url: "https://www.youtube.com/playlist?list=PLkSKwr-cmmSJ9uDy3y0H3dJlFdy2S7BH0",
        name: "youtube",
      },
      {
        url: "https://music.apple.com/us/album/somer-sonder-sondes-ep/1591264652?uo=4",
        name: "apple",
      },
      {
        url: "https://www.deezer.com/en/album/267000292",
        name: "deezer",
      },
      {
        url: "https://tidal.com/browse/album/201897495",
        name: "tidal",
      },
    ],
  },
  {
    id: 8,
    title: "Iets Is Fokken Fout Met My",
    url: "https://smarturl.it/f70dmi",
    img: "/static/img/artwork/somer-sonder-sondes.jpeg",
    details: [
      {
        key: "Title",
        value: "Iets Is Fokken Fout Met My",
      },
      {
        key: "Album",
        value: "Somer Sonder Sondes",
      },
      {
        key: "Released",
        value: "17 November 2021",
      },
      {
        key: "Label",
        value: "Independent",
      },
      {
        key: "Genre",
        value: "Afrikaans Rock",
      },
      {
        key: "Artwork",
        value: "@nannaventer",
        href: "http://instagram.com/nannaventer",
      },
      {
        key: "Produced",
        value: "@joellismusic",
        href: "http://instagram.com/joellismusic",
      },
      {
        key: "Lyrics",
        value: "Here",
        navlink: "/lyrics/iets-is-fokken-fout-met-my",
      },
    ],
    released: true,
    stores: [
      {
        url: "https://open.spotify.com/track/2eAIWv3uw81EZVjQj7MeGk",
        name: "spotify",
      },
      {
        url: "https://www.youtube.com/watch?v=A-g0e-LFRNE",
        name: "youtube",
      },
      {
        url: "https://music.apple.com/us/album/iets-is-fokken-fout-met-my-single/1590544838",
        name: "apple",
      },
      {
        url: "https://www.deezer.com/en/album/196511051256",
        name: "deezer",
      },
      {
        url: "https://tidal.com/browse/album/201359298",
        name: "tidal",
      },
    ],
  },
  {
    id: 7,
    title: "Vir Konteks",
    url: "http://smarturl.it/8sp2cf",
    img: "/static/img/artwork/vir-konteks.jpg",
    details: [
      {
        key: "Title",
        value: "Vir Konteks",
      },
      {
        key: "Album",
        value: "Vir Konteks",
      },
      {
        key: "Released",
        value: "11 September 2020",
      },
      {
        key: "Label",
        value: "Independent",
      },
      {
        key: "Genre",
        value: "Afrikaans Rock",
      },
      {
        key: "Artwork",
        value: "@nannaventer",
        href: "http://instagram.com/nannaventer",
      },
      {
        key: "Produced",
        value: "@joellismusic",
        href: "http://instagram.com/joellismusic",
      },
      {
        key: "Lyrics",
        value: "Here",
        navlink: "/lyrics/die-ruimtevaarder",
      },
    ],
    released: true,
    stores: [
      {
        url: "https://open.spotify.com/album/73HMULR675lWsQe4UR9Xl9",
        name: "spotify",
      },
      {
        url: "https://www.youtube.com/playlist?list=PLkSKwr-cmmSJ5mVfKXKu02g3cQ27qfwuU",
        name: "youtube",
      },
      {
        url: "https://music.apple.com/za/album/vir-konteks/1527413065",
        name: "apple",
      },
      {
        url: "https://www.deezer.com/us/album/166656472",
        name: "deezer",
      },
      {
        url: "https://listen.tidal.com/album/151800992",
        name: "tidal",
      },
    ],
  },
  {
    id: 6,
    title: "Die Ruimtevaarder",
    url: "http://smarturl.it/zmikge",
    img: "/static/img/artwork/die-ruimtevaarder.jpg",
    details: [
      {
        key: "Title",
        value: "Die Ruimtevaarder",
      },
      {
        key: "Album",
        value: "Vir Konteks",
      },
      {
        key: "Released",
        value: "21 August 2020",
      },
      {
        key: "Label",
        value: "Independent",
      },
      {
        key: "Genre",
        value: "Afrikaans Rock",
      },
      {
        key: "Artwork",
        value: "@nannaventer",
        href: "http://instagram.com/nannaventer",
      },
      {
        key: "Produced",
        value: "@joellismusic",
        href: "http://instagram.com/joellismusic",
      },
      {
        key: "Lyrics",
        value: "Here",
        navlink: "/lyrics/die-ruimtevaarder",
      },
    ],
    released: true,
    stores: [
      {
        url: "https://open.spotify.com/album/7D9IOtsvnt7zaPMFh1e6LL",
        name: "spotify",
      },
      {
        url: "https://www.youtube.com/watch?v=W7CxYvNzTRk",
        name: "youtube",
      },
      {
        url: "https://music.apple.com/za/album/die-ruimtevaarder…ngle/1523503209?ls=1&app=music&at=11lEW&ct=zmikge",
        name: "apple",
      },
      {
        url: "https://www.deezer.com/track/966618122",
        name: "deezer",
      },
      {
        url: "https://listen.tidal.com/album/148590660",
        name: "tidal",
      },
    ],
  },
  {
    id: 5,
    title: "Mors Jou Drank",
    url: "http://smarturl.it/sp1ih4",
    img: "/static/img/artwork/mors-jou-drank.jpg",
    details: [
      {
        key: "Title",
        value: "Mors Jou Drank",
      },
      {
        key: "Album",
        value: "Vir Konteks",
      },
      {
        key: "Released",
        value: "5 June 2020",
      },
      {
        key: "Label",
        value: "Independent",
      },
      {
        key: "Genre",
        value: "Afrikaans Punk Rock",
      },
      {
        key: "Artwork",
        value: "@nannaventer",
        href: "http://instagram.com/nannaventer",
      },
      {
        key: "Produced",
        value: "@joellismusic",
        href: "http://instagram.com/joellismusic",
      },
      {
        key: "Lyrics",
        value: "Here",
        navlink: "/lyrics/mors-jou-drank",
      },
    ],
    released: true,
    stores: [
      {
        url: "https://open.spotify.com/track/4VQQAFMFsTsT9cJXTjXmdg",
        name: "spotify",
      },
      {
        url: "https://youtu.be/4oEB96JVDs8",
        name: "youtube",
      },
      {
        url: "https://itunes.apple.com/za/album/mors-jou-drank/1514359656?i=1514359657&ls=1&app=music&at=11lEW&ct=sp1ih4%7Cfacebook.com",
        name: "apple",
      },
      {
        url: "https://www.deezer.com/track/966618122",
        name: "deezer",
      },
      {
        url: "https://listen.tidal.com/album/142229619",
        name: "tidal",
      },
    ],
  },
  {
    id: 4,
    title: "Die Happy Song",
    url: "http://smarturl.it/bmwd1e",
    img: "/static/img/artwork/die-happy-song.jpg",
    details: [
      {
        key: "Title",
        value: "Die Happy Song",
      },
      {
        key: "Album",
        value: "Vir Konteks",
      },
      {
        key: "Released",
        value: "8 May 2020",
      },
      {
        key: "Label",
        value: "Independent",
      },
      {
        key: "Genre",
        value: "Afrikaans Pop Rock",
      },
      {
        key: "Artwork",
        value: "@nannaventer",
        href: "http://instagram.com/nannaventer",
      },
      {
        key: "Produced",
        value: "@joellismusic",
        href: "http://instagram.com/joellismusic",
      },
      {
        key: "Lyrics",
        value: "Here",
        navlink: "/lyrics/die-happy-song",
      },
    ],
    released: true,
    stores: [
      {
        url: "https://open.spotify.com/track/620QSRMUb7GNIoN52MChay",
        name: "spotify",
      },
      {
        url: "https://music.youtube.com/watch?v=qEm3TA12TNI",
        name: "youtube",
      },
      {
        url: "https://music.apple.com/us/album/die-happy-song-single/1506182762?uo=4&app=itunes&at=1001lry3&ct=dashboard&ls=1",
        name: "apple",
      },
      {
        url: "https://www.deezer.com/en/album/139845692",
        name: "deezer",
      },
      {
        url: "https://listen.tidal.com/album/136305954",
        name: "tidal",
      },
    ],
  },
  {
    id: 3,
    title: "Velde",
    url: "http://smarturl.it/v079hy",
    img: "/static/img/artwork/velde.jpg",
    details: [
      {
        key: "Title",
        value: "Velde",
      },
      {
        key: "Album",
        value: "Vir Konteks",
      },
      {
        key: "Released",
        value: "20 March 2020",
      },
      {
        key: "Label",
        value: "Independent",
      },
      {
        key: "Genre",
        value: "Afrikaans Pop Rock",
      },
      {
        key: "Artwork",
        value: "@nannaventer",
        href: "http://instagram.com/nannaventer",
      },
      {
        key: "Produced",
        value: "@joellismusic",
        href: "http://instagram.com/joellismusic",
      },
      {
        key: "Music Video",
        value: "YouTube",
        href: "https://www.youtube.com/watch?v=2OeLrsLnCio",
      },
      {
        key: "Lyrics",
        value: "Here",
        navlink: "/lyrics/velde",
      },
    ],
    released: true,
    stores: [
      {
        url: "https://open.spotify.com/track/18URL8MhL7OIWW7TfYMOZX",
        name: "spotify",
      },
      {
        url: "https://music.youtube.com/watch?v=2OeLrsLnCio",
        name: "youtube",
      },
      {
        url: "https://music.apple.com/za/album/velde/1500398910?app=music&i=1500399278&ign-mpt=uo%3D4",
        name: "apple",
      },
      {
        url: "https://www.deezer.com/en/album/133428252",
        name: "deezer",
      },
      {
        url: "https://listen.tidal.com/album/132439101",
        name: "tidal",
      },
    ],
  },
  {
    id: 2,
    title: "24",
    url: "http://smarturl.it/7qsim6",
    img: "/static/img/artwork/24.jpg",
    details: [
      {
        key: "Title",
        value: "24",
      },
      {
        key: "Album",
        value: "Single",
      },
      {
        key: "Released",
        value: "6 February 2020",
      },
      {
        key: "Label",
        value: "Independent",
      },
      {
        key: "Genre",
        value: "Afrikaans Punk Rock",
      },
      {
        key: "Artwork",
        value: "@kontrasband",
        href: "http://instagram.com/kontrasband",
      },
      {
        key: "Produced",
        value: "@joellismusic",
        href: "http://instagram.com/joellismusic",
      },
      {
        key: "Lyrics",
        value: "Here",
        navlink: "/lyrics/24",
      },
    ],
    released: true,
    stores: [
      {
        url: "https://open.spotify.com/album/7DtfWJznXi8iLAIuB0XVCK",
        name: "spotify",
      },
      {
        url: "https://www.youtube.com/watch?v=SG1yiOThNO0",
        name: "youtube",
      },
      {
        url: "https://itunes.apple.com/za/album/24-single/1493972157?uo=4&ls=1&app=music&at=11lEW&ct=7qsim6",
        name: "apple",
      },
      {
        url: "https://www.deezer.com/album/125734002",
        name: "deezer",
      },
      {
        url: "https://listen.tidal.com/album/127503448",
        name: "tidal",
      },
    ],
  },
  {
    id: 1,
    title: "Side B",
    url: "http://smarturl.it/xya64y",
    img: "/static/img/artwork/sideb.jpg",
    details: [
      {
        key: "Title",
        value: "Side B",
      },
      {
        key: "Album",
        value: "Side B",
      },
      {
        key: "Released",
        value: "3 March 2019",
      },
      {
        key: "Label",
        value: "Independent",
      },
      {
        key: "Genre",
        value: "Afrikaans Punk Rock",
      },
      {
        key: "Artwork",
        value: "@nannaventer",
        href: "http://instagram.com/nannaventer",
      },
      {
        key: "Produced",
        value: "@joellismusic",
        href: "http://instagram.com/joellismusic",
      },
      {
        key: "Lyrics",
        value: "Here",
        navlink: "/lyrics/side-b",
      },
    ],
    released: true,
    stores: [
      {
        url: "https://open.spotify.com/artist/13mo5g6PR09u3Rq8bEstY2?si=sytJqeERQw2EI8u1mZn0UQ",
        name: "spotify",
      },
      {
        url: "https://www.youtube.com/channel/UC3PTVGTBJz9UmWwOxhS1i9g",
        name: "youtube",
      },
      {
        url: "https://itunes.apple.com/za/artist/kontras/1445772282",
        name: "apple",
      },
      {
        url: "https://www.deezer.com/en/album/88792602",
        name: "deezer",
      },
      {
        url: "https://listen.tidal.com/album/104921335",
        name: "tidal",
      },
    ],
  },
  {
    id: 0,
    title: "Side A",
    url: "http://smarturl.it/80n151",
    img: "/static/img/artwork/sidea.jpg",
    details: [
      {
        key: "Title",
        value: "Side A",
      },
      {
        key: "Album",
        value: "Side A",
      },
      {
        key: "Released",
        value: "4 December 2018",
      },
      {
        key: "Label",
        value: "Independent",
      },
      {
        key: "Genre",
        value: "Afrikaans Punk Rock",
      },
      {
        key: "Artwork",
        value: "@nannaventer",
        href: "http://instagram.com/nannaventer",
      },
      {
        key: "Produced",
        value: "@joellismusic",
        href: "http://instagram.com/joellismusic",
      },
      {
        key: "Lyrics",
        value: "Here",
        navlink: "/lyrics/side-a",
      },
    ],
    released: true,
    stores: [
      {
        url: "https://open.spotify.com/artist/13mo5g6PR09u3Rq8bEstY2?si=sytJqeERQw2EI8u1mZn0UQ",
        name: "spotify",
      },
      {
        url: "https://www.youtube.com/channel/UC3PTVGTBJz9UmWwOxhS1i9g",
        name: "youtube",
      },
      {
        url: "https://itunes.apple.com/za/artist/kontras/1445772282",
        name: "apple",
      },
      {
        url: "https://www.deezer.com/en/album/80215072",
        name: "deezer",
      },
      {
        url: "https://listen.tidal.com/album/99714112",
        name: "tidal",
      },
    ],
  },
];
