export default {
  title: "Soos 'n Brander",
  tracks: [
    {
      title: "Soos 'n Brander",
      lyrics:
        "Kleur my in soos 'n stellenbosch sonsak\nKom ons vat die pad\nOns kan tussen die heuwels in verdwyn\nDrink wyn en vergeet van ons pyn\nEn ek het niks om vir jou te gee nie\nMaar eks nie bang om dit te deel nie\nJy steel my asem soos 'n laaste sigaret\nEn ek wil ek wil vir jou se\n \nLe 'n bietjie langer\nKom ons gaan 'n bietjie verder\nKom ek wees die mure\nEn jy wees die verwer\n \nPraat 'n bietjie sagter\nVat 'n bietjie harder\nJy is net te pragtig\nRol oor my soos 'n brander\n \nOmring my soos 'n wolk om tafelberg\nLaat my aanbid in jou kerk\nEk soek alles en dan nog 'n bietjie meer\nEk sal my aan jou lyf bekeer\nEk ken die taal wat jou lippe praat\nVeral as dit laat nag raak\nKom ons kruip weg in die bed\nEn ek wil ek wil vir jou se\n \nLe 'n bietjie langer\nKom ons gaan 'n bietjie verder\nKom ek wees die mure\nEn jy wees die verwer\n \nPraat 'n bietjie sagter\nVat 'n bietjie harder\nJy is net te pragtig\nRol oor my soos 'n brander\n \n \nLe 'n bietjie langer X 7\n \nLe 'n bietjie langer\nKom ons gaan 'n bietjie verder\nKom ek wees die mure\nEn jy wees die verwer\n \nPraat 'n bietjie sagter\nVat 'n bietjie harder\nJy is net te pragtig\nRol oor my soos 'n brander\n",
    },
  ],
};
