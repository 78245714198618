export default {
  title: "Papierstraal",
  tracks: [
    {
      title: "Papierstraal",
      lyrics: `Ek’s siek vir die heeltyd kak besluite maak
      en myself die heeltyd in die steek te laat
      Blameer dit op my jeugdigheid
      Amper spring my hart uit my borskas uit

      Op 27 bang vir ‘n hartaanval
      sê my hoe die fok is dit normaal?
      Sal beter na myself moet kyk
      kitsgeluk sal my onderkry

      Ek vou ‘n papierstraal
      en steek dit aan die brand
      Almal wil vlieg maar
      niemand weet om te land
      Ek is so dankbaar
      jy’s aan my kant
      Ek is so jammer
      dit het so lank gevat

      oh woho ooh woho oh oh  x4

      Wel, alkohol is geen sielkundige nie
      maar dis mos twee keer so lekker om dubbel te sien
      Blameer dit op die vlugtigheid
      terwyl ons uitkyk by die agterruit

      Begin my angs en vrees nou agterlaat
      Meditations medication, ek sweer my bra
      Dit voel of als net ligter word
      hoe meer jy het hoe meer gaan jy kort

      Ek vou ‘n papierstraal
      en steek dit aan die brand
      Almal wil vlieg maar
      niemand weet om land
      Ek is so dankbaar
      jy’s aan my kant
      Ek is so jammer
      dit het so lank gevat

      oh woho ooh woho oh oh  x4

      Ek vou ‘n papierstraal
      en steek dit aan die brand
      Almal wil vlieg maar
      niemand weet om land
      Ek is so dankbaar
      jy’s aan my kant
      Ek is so jammer
      dit het so lank gevat
      `,
    },
  ],
};
